import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { Store } from 'store'
import { connect } from 'react-redux'
import AuthStore from 'store/Auth'
import { authorizeAdminPage } from 'utils/authorization'
import AdminNavBar from './admin/AdminNavBar'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
  AspectRatioOutlined,
  BurstModeOutlined,
  TranslateOutlined,
  StarBorderOutlined,
  ContactSupportOutlined,
  TextFieldsOutlined,
  AssessmentOutlined
} from '@mui/icons-material'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import MyRequests from 'components/requests/requetsmy/MyRequests'
import AllRequests from 'components/requests/requestsall/AllRequests'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { RequestType } from 'models/SelfServiceRequest'
import RequestIndex from 'components/requests/RequestIndex'
import AdWords from './admin/adwords/AdWords'
import ContactUsTiles from './admin/contactustiles/ContactUsTiles'
import FeaturedResults from './admin/featuredResults/FeaturedResults'
import Translations from './admin/translations/Translations'
import Popups from './admin/popups/Popups'
import DidYouMean from './admin/didyoumean/DidYouMean'
import { SettingMenuTypes } from 'models/SettingMenuTypes'
import MetricsDashboard from './dashboard/MetricsDashboard'
import Subscriptions from './admin/subscriptions/Subscriptions'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'

interface ContentsProps {}

type AllContentsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ContentsProps

function Contents(props: AllContentsProps): JSX.Element {
  const {
    fetchAdminPagesAuthorization,
    adminPagesAuthorizationFetched,
    adminPagesAuthorization
  } = props

  const navigateFunction = useNavigate()

  const [isAdminPagesAuthorized, setIsAdminPagesAuthorized] = useState<
    boolean | undefined
  >(undefined)
  const [hasChanges, setHasChanges] = useState(false)
  const [showSettings, setShowSettings] = useState<null | SettingMenuTypes>(
    null
  )
  const hasActiveChanges = useRef(false)

  const menuItems = useRef([
    {
      icon: <BurstModeOutlined fontSize={'inherit'} />,
      linkText: 'My Requests',
      url: '/myrequests/'
    } /* ,
    {
      icon: <AssessmentOutlined fontSize={'inherit'} />,
      linkText: 'Metrics Dashboard',
      url: '/metricsdashboard/'
    } */
  ])

  const setHasActiveChanges = (newValue: boolean) => {
    setHasChanges(newValue)
    hasActiveChanges.current = newValue
  }

  const handleAdSettingsClose = (event: BeforeUnloadEvent) => {
    if (hasActiveChanges.current) {
      event.returnValue =
        'Are you sure you want to leave this page? All changes will be lost.'
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleAdSettingsClose)

    return () => {
      window.removeEventListener('beforeunload', handleAdSettingsClose)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //fetch user rights
    if (!adminPagesAuthorizationFetched) {
      fetchAdminPagesAuthorization(ESSettingsGlobalVariables.getUPN())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (adminPagesAuthorizationFetched) {
      const authorized = authorizeAdminPage(adminPagesAuthorization)

      if (authorized) {
        menuItems.current = [
          {
            icon: <BurstModeOutlined fontSize={'inherit'} />,
            linkText: 'All Requests',
            url: '/allrequests/'
          },
          {
            icon: <BurstModeOutlined fontSize={'inherit'} />,
            linkText: 'Ad Settings',
            url: '/adsettings/'
          },
          {
            icon: <AspectRatioOutlined fontSize={'inherit'} />,
            linkText: 'Popup Settings',
            url: '/popupsettings/'
          },
          {
            icon: <TranslateOutlined fontSize={'inherit'} />,
            linkText: 'Translation Settings',
            url: '/translationsettings/'
          },
          {
            icon: <TranslateOutlined fontSize={'inherit'} />,
            linkText: 'OI / Teams Translation Settings',
            url: '/oitranslationsettings/'
          },
          {
            icon: <TranslateOutlined fontSize={'inherit'} />,
            linkText: 'ES-Settings Translation',
            url: '/settingstranslationsettings/'
          },
          {
            icon: <StarBorderOutlined fontSize={'inherit'} />,
            linkText: 'Featured Results',
            url: '/featuredresults/'
          },
          {
            icon: <ContactSupportOutlined fontSize={'inherit'} />,
            linkText: 'Contact Us Tiles',
            url: '/contactustiles/'
          },
          {
            icon: <TextFieldsOutlined fontSize={'inherit'} />,
            linkText: 'Did You Mean',
            url: '/didyoumean/'
          },
          {
            icon: <SubscriptionsIcon fontSize={'inherit'} />,
            linkText: 'Subscriptions',
            url: '/subscriptions/'
          },
          {
            icon: <AssessmentOutlined fontSize={'inherit'} />,
            linkText: 'Metrics Dashboard',
            url: '/metricsdashboard/'
          }
        ]
      }

      setIsAdminPagesAuthorized(authorized)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminPagesAuthorizationFetched])

  useEffect(() => {
    if (
      window.location.pathname === '/' &&
      isAdminPagesAuthorized !== undefined
    ) {
      if (window.location && window.location.hash) {
        switch (window.location.hash) {
          case '#/request/adword/':
            return
          case '#/request/featuredresult/':
            return
        }

        let queryparams = ''
        if (window.location.hash.indexOf('?') !== -1) {
          queryparams = window.location.hash.split('?')[1]
        }

        isAdminPagesAuthorized
          ? navigateFunction(
              `/allrequests/${queryparams ? `?${queryparams}` : ''}`,
              { replace: true }
            )
          : navigateFunction(
              `/myrequests/${queryparams ? `?${queryparams}` : ''}`,
              { replace: true }
            )
      } else {
        isAdminPagesAuthorized
          ? navigateFunction('/allrequests/', { replace: true })
          : navigateFunction('/myrequests/', { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminPagesAuthorized])

  if (adminPagesAuthorizationFetched && isAdminPagesAuthorized !== undefined) {
    return (
      <>
        <AdminNavBar
          menuItems={menuItems.current}
          hasChanges={hasChanges}
          setHasChanges={setHasActiveChanges}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          //showNewsLetterSubscription={!isAdminPagesAuthorized} Bug 2111560: Disabled subscription until process is defined
          showNewsLetterSubscription={false}
        />
        {isAdminPagesAuthorized && (
          <Routes>
            <Route path={'/allrequests/'} element={<AllRequests />} />
            <Route
              path={'/adsettings/'}
              element={<AdWords setHasChanges={setHasActiveChanges} />}
            />
            <Route
              path={'/popupsettings/'}
              element={<Popups setHasChanges={setHasActiveChanges} />}
            />
            <Route
              path={'/translationsettings/'}
              element={
                <Translations
                  adminSettingType={adminSettingTypes.translations}
                  setHasChanges={setHasActiveChanges}
                  resetChanges={!hasChanges}
                />
              }
            />
            <Route
              path={'/oitranslationsettings/'}
              element={
                <Translations
                  adminSettingType={adminSettingTypes.oitranslations}
                  setHasChanges={setHasActiveChanges}
                  resetChanges={!hasChanges}
                />
              }
            />
            <Route
              path={'/settingstranslationsettings/'}
              element={
                <Translations
                  adminSettingType={adminSettingTypes.settingstranslations}
                  setHasChanges={setHasActiveChanges}
                  resetChanges={!hasChanges}
                />
              }
            />
            <Route
              path={'/featuredresults/'}
              element={<FeaturedResults setHasChanges={setHasActiveChanges} />}
            />
            <Route
              path={'/contactustiles/'}
              element={<ContactUsTiles setHasChanges={setHasActiveChanges} />}
            />
            <Route
              path={'/didyoumean/'}
              element={<DidYouMean setHasChanges={setHasActiveChanges} />}
            />
            <Route
              path={'/subscriptions/'}
              element={<Subscriptions setHasChanges={setHasActiveChanges} />}
            />
            <Route path={'/metricsdashboard/'} element={<MetricsDashboard />} />
          </Routes>
        )}
        {!isAdminPagesAuthorized && (
          <Routes>
            <Route
              path={'/myrequests/'}
              element={<MyRequests setShowSettings={setShowSettings} />}
            />
            {/* <Route path={'/metricsdashboard/'} element={<MetricsDashboard />} /> */}
            <Route
              path={'/request/adword/'}
              element={<RequestIndex requestType={RequestType.AdWord} />}
            />
            <Route
              path={'/request/featuredresult/'}
              element={
                <RequestIndex requestType={RequestType.FeaturedResult} />
              }
            />
          </Routes>
        )}
      </>
    )
  } else {
    return <></>
  }
}

const mapStateToProps = (state: Store) => {
  return {
    adminPagesAuthorizationFetched:
      AuthStore.selectors.getAdminPagesAuthorizationFetched(state),
    adminPagesAuthorization:
      AuthStore.selectors.getAdminPagesAuthorization(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    fetchAdminPagesAuthorization: (upn: string) =>
      dispatch(AuthStore.actions.fetchAdminPagesAuthorization(upn))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
