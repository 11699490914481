import {
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import {
  editFormErrorKeys,
  checkIsValidNewsLetterSubscription
} from 'utils/admin/adminFormUtils'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import { supportedMemberFirms } from 'constants/supportedMemberFirms'
import DefaultFormField from 'components/contents/common/DefaultFormField'
import { AddCircle } from '@mui/icons-material'
import { IKeyWordChipData } from 'models/FormModels'

export interface IEditFormSubscriptionProps {
  item: INewsLetterSubscription
  setIsValid: (isValid: boolean) => void
}

export default function EditFormSubscription(
  props: IEditFormSubscriptionProps
): JSX.Element {
  const { item, setIsValid } = props
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  const [currentKeyWordToAdd, setCurrentKeyWordToAdd] = useState<string>('')
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<
    Array<IKeyWordChipData>
  >([])

  useEffect(() => {
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const selectedTermsRef = useRef(selectedSearchTerms)

  ///error
  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const validateItem = (): void => {
    let errorList: IErrorListObject[] = checkIsValidNewsLetterSubscription(item)

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  const isValidMail = (mail: string) => {
    return !!mail.match(/^[\w.]+@[\w.]+$/g)
  }

  /** handle keyWord Add and delete */
  const handleKeyWordDelete = (termToDelete: IKeyWordChipData) => {
    const newValue = selectedSearchTerms.filter(
      (chip) => chip.key !== termToDelete.key
    )
    setSelectedSearchTerms(newValue)

    item.upn = newValue.map((chip: IKeyWordChipData) => chip.label).join(',')

    validateItem()
  }

  const handleKeyWordAdd = (keyWordToAdd: string) => {
    if (keyWordToAdd) {
      const newKeyWords = [
        ...selectedSearchTerms.map((chip: IKeyWordChipData) => {
          chip.showWarning = false
          return chip
        })
      ]

      const keywords = keyWordToAdd.split(',')
      let index = 1
      keywords.forEach((keyword: string) => {
        if (keyword && keyword.trim()) {
          const existingKeyWord = newKeyWords.find(
            (chipData: IKeyWordChipData) =>
              chipData.label.trim().toLowerCase() ===
              keyword.trim().toLowerCase()
          )

          if (!existingKeyWord) {
            newKeyWords.push({
              key: selectedSearchTerms.length + index,
              label: keyword.trim().toLowerCase(),
              showError: !isValidMail(keyword.trim())
            })

            index = index + 1
          } else if (existingKeyWord.key <= selectedSearchTerms.length) {
            existingKeyWord.showWarning = true
          }
        }
      })

      if (
        selectedTermsRef.current.some(
          (chip: IKeyWordChipData) => chip.showWarning
        )
      ) {
        setTimeout(() => {
          setSelectedSearchTerms(
            selectedTermsRef.current.map((chip: IKeyWordChipData) => {
              chip.showWarning = false
              return chip
            })
          )
        }, 2000)
      }

      selectedTermsRef.current = newKeyWords
      setSelectedSearchTerms(newKeyWords)

      item.upn = newKeyWords
        .map((chip: IKeyWordChipData) => chip.label)
        .join(',')
    }
    validateItem()
  }

  const filterSearchTermInput = (input: string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /[`~!#$§%^*()|\=?;:'"<>\{\}\[\]\\\/]/gi
    return input.replace(regex, '')
  }

  return (
    <>
      {item && loaded && (
        <>
          <DefaultFormField
            label={"List of Email ID's"}
            required={true}
            description={"A list of comma separated email id's"}
            inputControl={
              <div
                className={`${classes.selectChipArea} ${
                  getError(editFormErrorKeys.upn).error
                    ? classes.selectChipAreaError
                    : ''
                }`}
              >
                <div className={classes.selectChipInnerArea}>
                  <div>
                    <TextField
                      id="input-with-icon-textfield"
                      size="small"
                      value={currentKeyWordToAdd}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.addKeyWordFieldAddButton}
                            onClick={() => {
                              if (currentKeyWordToAdd) {
                                handleKeyWordAdd(currentKeyWordToAdd)
                                setCurrentKeyWordToAdd('')
                              }
                            }}
                          >
                            <AddCircle />
                          </InputAdornment>
                        )
                      }}
                      onChange={(event) => {
                        setCurrentKeyWordToAdd(
                          filterSearchTermInput(event.target.value)
                        )
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter' && currentKeyWordToAdd) {
                          handleKeyWordAdd(currentKeyWordToAdd)
                          setCurrentKeyWordToAdd('')
                        }
                      }}
                      variant="outlined"
                      className={classes.addKeyWordField}
                    />
                  </div>
                  {selectedSearchTerms.map((data: IKeyWordChipData) => {
                    return (
                      <Chip
                        label={data.label}
                        onDelete={() => handleKeyWordDelete(data)}
                        className={
                          data.showError
                            ? [
                                classes.keyWordChip,
                                classes.keyWordChipError
                              ].join(' ')
                            : data.showWarning
                            ? [
                                classes.keyWordChip,
                                classes.keyWordChipWarning
                              ].join(' ')
                            : classes.keyWordChip
                        }
                      />
                    )
                  })}
                </div>
              </div>
            }
            hasError={getError(editFormErrorKeys.upn).error}
            errorText={getError(editFormErrorKeys.upn).helperText}
          />
          <FormControl style={{ marginTop: '10px' }} fullWidth size="small">
            <InputLabel id="select-label">Member firm *</InputLabel>
            <Select
              id="memberfirm-required"
              multiple={false}
              variant={'outlined'}
              defaultValue={item.memberFirm}
              size="small"
              label="Member firm *"
              onChange={(event: SelectChangeEvent<string>) => {
                item.memberFirm = event.target.value
              }}
            >
              {supportedMemberFirms.map((key: string) => {
                return (
                  <MenuItem key={key} value={key}>
                    <ListItemText primary={key} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </>
      )}
    </>
  )
}
