import { Column } from '@material-table/core'
import AdminTableTooltipClip from 'components/admin/common/AdminTableTooltipClip'
import AdminTableTooltipTitle from 'components/admin/common/AdminTableTooltipTitle'
import { dateFormatOptions } from 'constants/constants'
import createDOMPurify from 'dompurify'
import {
  ISelfServiceRequest,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import React from 'react'
import { stripHtml } from 'utils/string'
import InlineChangeNotification from 'components/contents/common/InlineChangeNotification'
import StatusClipRequest from 'components/admin/common/StatusClipRequests'
import { DropDownFilter } from '../../contents/common/DropDownFilter'
import { hasDateExpired } from 'utils/date'
import { AdsResult } from 'models/AdsResult'
import { FeaturedResult } from 'models/FeaturedResult'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import AccessAlarm from '@mui/icons-material/AccessAlarm'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'

const getStatusColor = (status: RequestStatus): any => {
  switch (status) {
    case RequestStatus.Submitted:
      return 'primary'
    case RequestStatus.Published:
      return 'success'
    case RequestStatus.ToBeDeleted:
    case RequestStatus.Declined:
      return 'error'
    case RequestStatus.Clarification:
      return 'secondary'
    default:
      return 'default'
  }
}

const DOMPurify = createDOMPurify(window)

/**
 * Generate popup columns list
 * @param handleRowRemoveChangeNotification Method to remove the hasChange flag
 * @returns columns list
 */
export const myRequestTableColumns = (
  handleRowRemoveChangeNotification: (rowData: ISelfServiceRequest) => void,
  handleRowRemoveCommentChangeNotification: (
    rowData: ISelfServiceRequest
  ) => void,
  handleRowUpdate: (rowData: ISelfServiceRequest) => void,
  toggleSnackbarVisibility: (visible: boolean) => void,
  setSnackbarMessage: (message: string) => void,
  intl: any
): Column<ISelfServiceRequest>[] => {
  const classes = getStylesAdminSettings()

  const myRequestTableColumns: Column<ISelfServiceRequest>[] = [
    {
      title: intl.formatMessage({
        id: 'table_column_id_label',
        defaultMessage: 'ID'
      }),
      field: 'id',
      type: 'string',
      cellStyle: {
        maxWidth: 90
      },
      headerStyle: {
        maxWidth: 90
      },
      filtering: false,
      render: (rowData: ISelfServiceRequest) => (
        <InlineChangeNotification
          rowData={rowData}
          id={rowData.id}
          handleRowRemoveChangeNotification={() =>
            handleRowRemoveChangeNotification(rowData)
          }
        />
      ),
      customSort: (a: ISelfServiceRequest, b: ISelfServiceRequest) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: intl.formatMessage({
        id: 'table_column_type_label',
        defaultMessage: 'Type'
      }),
      field: 'requestType',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipClip
          color={'primary'}
          label={rowData.requestType.toString()}
          variant={'outlined'}
          maxWidth={200}
        />
      ),
      width: 200,
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: true,
      filterComponent: (props) => (
        <DropDownFilter
          {...props}
          options={[
            RequestType.AdWord.toString(),
            RequestType.FeaturedResult.toString(),
            RequestType.SpellingSuggestion.toString()
          ]}
          multiple={true}
        />
      ),
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        return value.indexOf(rowData.requestType) !== -1
      }
    },
    {
      title: intl.formatMessage({
        id: 'table_column_title_label',
        defaultMessage: 'Title'
      }),
      field: 'title',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipTitle
          className={''}
          basedOn="words"
          unsafeHTML={rowData.title ? DOMPurify.sanitize(rowData.title) : ''}
          maxLine={4}
        />
      ),
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: false
    },
    {
      title: intl.formatMessage({
        id: 'table_column_description_label',
        defaultMessage: 'Description'
      }),
      field: 'description',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipTitle
          className={''}
          basedOn="words"
          unsafeHTML={
            rowData.description
              ? DOMPurify.sanitize(stripHtml(rowData.description))
              : ''
          }
          maxLine={2}
        />
      ),
      width: 200,
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: false
    },
    {
      title: intl.formatMessage({
        id: 'table_column_image_label',
        defaultMessage: 'Image'
      }),
      field: 'image',
      type: 'string',
      render: (rowData: ISelfServiceRequest) =>
        rowData.imagePreview ? (
          <img
            src={`data:${rowData.imagePreview}`}
            alt="ad url"
            style={{
              width: 80,
              height: 40
            }}
          />
        ) : (
          ''
        ),
      filtering: false,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      sorting: false
    },
    {
      title: intl.formatMessage({
        id: 'table_column_link_label',
        defaultMessage: 'Link'
      }),
      field: 'link',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: ISelfServiceRequest) => {
        const url =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].link
            : (rowData.itemData as FeaturedResult).BestBetUrl

        return (
          <AdminTableTooltipTitle
            basedOn="words"
            text={url}
            maxLine={1}
            className={classes.rowEllipsis}
          />
        )
      },
      filtering: true,
      filterComponent: findDefaultFilterComponent,
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        const url =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].link
            : rowData.requestType === RequestType.FeaturedResult
            ? (rowData.itemData as FeaturedResult).BestBetUrl
            : ''

        if (!url) return false

        return url.toLowerCase().indexOf(value.toLowerCase()) !== -1
      },
      sorting: false
    },
    {
      title: intl.formatMessage({
        id: 'table_column_search_terms_label',
        defaultMessage: 'Search Terms'
      }),
      field: 'search_terms',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => {
        const keywords =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].search_terms
            : (rowData.itemData as FeaturedResult).BestBetKeywords
        return (
          <div>
            {keywords &&
              keywords
                .slice(0, 3)
                .map((searchTerm: string, index: number) => (
                  <AdminTableTooltipClip
                    label={searchTerm}
                    maxWidth={90}
                    variant="outlined"
                    key={index}
                    addMargin={true}
                  />
                ))}
          </div>
        )
      },
      filtering: true,
      filterComponent: findDefaultFilterComponent,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        const keywords =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].search_terms
            : rowData.requestType === RequestType.FeaturedResult
            ? (rowData.itemData as FeaturedResult).BestBetKeywords
            : []

        return keywords.some(
          (key: string) => key.toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
      },
      sorting: false
    },
    {
      title: intl.formatMessage({
        id: 'table_column_status_label',
        defaultMessage: 'Status'
      }),
      field: 'status',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => {
        return (
          <StatusClipRequest
            color={getStatusColor(rowData.status)}
            label={intl.formatMessage({
              id: `table_column_status_${rowData.status
                .toString()
                .toLowerCase()}`,
              defaultMessage:
                rowData.status !== RequestStatus.Clarification
                  ? rowData.status.toString()
                  : 'Pending Clarification'
            })}
            variant={'outlined'}
            maxWidth={200}
            rowData={rowData}
            {...(rowData.status === RequestStatus.Clarification && {
              additionalTooltip: intl
                .formatMessage({
                  id: 'table_column_status_tooltip',
                  defaultMessage:
                    'Please edit the requested {requestType} and provide the required informations. Submit the change to resolve the pending clarification.'
                })
                .replace(
                  '{requestType}',
                  rowData.requestType === RequestType.FeaturedResult
                    ? 'Featured Result'
                    : 'AdWord'
                )
            })}
            handleRowUpdate={handleRowUpdate}
            isAdminRequest={false}
            toggleSnackbarVisibility={toggleSnackbarVisibility}
            setSnackbarMessage={setSnackbarMessage}
            handleRowRemoveCommentChangeNotification={
              handleRowRemoveCommentChangeNotification
            }
          />
        )
      },
      width: 220,
      cellStyle: {
        maxWidth: 220
      },
      headerStyle: {
        maxWidth: 220
      },
      filtering: true,
      filterComponent: (props) => (
        <DropDownFilter
          {...props}
          options={[
            RequestStatus.Draft.toString(),
            RequestStatus.Submitted.toString(),
            RequestStatus.Published.toString(),
            RequestStatus.Declined.toString(),
            RequestStatus.Clarification.toString(),
            'Expiring'
          ]}
          multiple={true}
        />
      ),
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        let expiration = false
        if (
          value.indexOf('Expiring') !== -1 &&
          rowData.requestType !== RequestType.SpellingSuggestion
        ) {
          const endDate = new Date(rowData.endDate ? rowData.endDate : '')
          const differenceInDays = dayjs().diff(new Date(endDate), 'days', true)
          expiration = differenceInDays > -30
        }

        return (
          (value.indexOf('Expiring') !== -1 ? expiration : false) ||
          value.indexOf(rowData.status) !== -1
        )
      }
    },
    {
      title: intl.formatMessage({
        id: 'table_column_modified_on_label',
        defaultMessage: 'Modified On'
      }),
      field: 'modifiedDate',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: ISelfServiceRequest) => {
        if (rowData.modifiedDate)
          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            new Date(rowData.modifiedDate)
          )
        return ''
      },
      filtering: false,
      defaultSort: 'desc'
    },
    {
      title: intl.formatMessage({
        id: 'table_column_expire_date_label',
        defaultMessage: 'Expiry Date'
      }),
      field: 'endDate',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: ISelfServiceRequest) => {
        let endDate = ''

        if (rowData.endDate) {
          let parsedDate = new Date(rowData.endDate)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          endDate = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        const expired = hasDateExpired(endDate)

        return (
          <div
            style={{
              position: 'relative',
              paddingBottom: rowData.expiresSoon || expired ? '17px' : '6px'
            }}
          >
            <span>
              {endDate}
              <span style={{ verticalAlign: 'super' }}>
                {rowData.expiresSoon && (
                  <Tooltip
                    title={intl
                      .formatMessage({
                        id: 'table_column_expire_tooltip_soon',
                        defaultMessage:
                          '{requestType} will expire within the next 30 days.'
                      })
                      .replace(
                        '{requestType}',
                        rowData.requestType === RequestType.AdWord
                          ? 'Adword'
                          : 'Featured Result'
                      )}
                  >
                    <AccessAlarm
                      style={{ width: '20px', color: 'rgba(234,170,0,1)' }}
                    />
                  </Tooltip>
                )}
                {expired && !rowData.expiresSoon && (
                  <Tooltip
                    title={intl
                      .formatMessage({
                        id: 'table_column_expire_tooltip_has',
                        defaultMessage: '{requestType} has expired.'
                      })
                      .replace(
                        '{requestType}',
                        rowData.requestType === RequestType.AdWord
                          ? 'Adword'
                          : 'Featured Result'
                      )}
                  >
                    <AccessAlarm style={{ width: '20px', color: '#bc204b' }} />
                  </Tooltip>
                )}
              </span>
            </span>
          </div>
        )
      },
      filtering: false
    },
    {
      title: 'UPN',
      field: 'upn',
      type: 'string',
      hidden: true,
      export: true
    }
  ]

  return myRequestTableColumns
}
