import React, { Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  Typography,
  Grid,
  IconButton
} from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { makeStyles } from '@mui/styles'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import AuthStore from 'store/Auth'
import { getStylesSettings } from 'styles/contents/common/Settings'
import { FormattedMessage, useIntl } from 'react-intl'
import { IDeputy, IUserSetting } from 'models/UserSettings'
import HighlightOff from '@mui/icons-material/HighlightOff'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PersonIcon from '@mui/icons-material/Person'
import { IPeopleResult } from 'models/People'
import PeopleSearch from './PeopleSearch'
import { SettingMenuTypes } from 'models/SettingMenuTypes'
import * as Config from '../../../config'

export interface SettingsProps {
  showSettings: string
  onClose: () => void
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  SettingsProps

function Settings(props: AllProps) {
  const {
    showSettings,
    userSettings,
    pictureUrl,
    displayName,
    onClose,
    setUserSettings,
    aadInfo,
    hasUserSettingsBeenFetched
  } = props

  const [enableNotifications, setEnableNotifications] = useState(
    userSettings.EnableNotifications
  )
  const [deputies, setDeputies] = useState<IDeputy[]>([])
  const [showPeoplePicker, setShowPeoplePicker] = useState(
    showSettings === SettingMenuTypes.Deputy ? true : false
  )

  const classes = getStylesSettings()
  const intl = useIntl()

  const userProfileStyles = makeStyles((theme) => ({
    peopleProfilepic: {
      height: 80,
      width: 80,
      backgroundImage: `url(${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }), url(${PeoplePlaceholder}) !important`,
      backgroundSize: '80px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#b1b1b1',
      borderRadius: '50%'
    },
    defaultProfilepic: {
      height: 80,
      width: 80,
      backgroundImage: `url(${PeoplePlaceholder})`,
      backgroundColor: '#b1b1b1',
      backgroundSize: '80px',
      borderRadius: '50%'
    }
  }))

  const userProfileClasses = userProfileStyles()

  const removeDeputy = (upn: string): void => {
    setDeputies(
      deputies.filter((dep: IDeputy) => dep.userPrincipalName !== upn)
    )
  }

  const addDeputy = (user: IPeopleResult): void => {
    const newDep = deputies.filter(
      (dep: IDeputy) => dep.userPrincipalName !== user.userPrincipalName
    )

    newDep.push({
      userPrincipalName: user.userPrincipalName,
      mail: user.mail,
      displayName: user.displayName
    })
    setDeputies(newDep)
    setShowPeoplePicker(false)
  }

  const onCancelButtonClick = () => {
    onClose()

    setEnableNotifications(userSettings.EnableNotifications)
    setDeputies([...userSettings.Deputies])
  }

  const onSaveButtonClick = () => {
    onClose()

    userSettings.EnableNotifications = enableNotifications

    const oldDeputies = userSettings.Deputies.map(
      (d: IDeputy) => d.userPrincipalName
    )
    const newDeputies = deputies.map((d: IDeputy) => d.userPrincipalName)

    const deputiesAdded = deputies.filter((d) => {
      return !oldDeputies.includes(d.userPrincipalName)
    })
    const deputiesRemoved = userSettings.Deputies.filter((d) => {
      return !newDeputies.includes(d.userPrincipalName)
    })

    userSettings.Deputies = [...deputies]

    // update settings
    setUserSettings(
      userSettings,
      oldDeputies.join(',') !== newDeputies.join(','),
      deputiesAdded,
      deputiesRemoved
    )
  }

  useEffect(() => {
    if (hasUserSettingsBeenFetched) {
      setDeputies([...userSettings.Deputies])
      setEnableNotifications(userSettings.EnableNotifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserSettingsBeenFetched])

  // Construct user name
  let userName = displayName

  //build version
  let buildNumber = Config.BUILD_NUMBER
  const index = buildNumber.indexOf('_')
  if (index > 0) {
    buildNumber = buildNumber.substring(index + 1)
  }

  return (
    <Dialog
      id={'con-settings-01'}
      open={!!showSettings}
      onClose={onClose}
      className={classes.dialogContainer}
    >
      <DialogContent className={classes.dialogContent}>
        <Box id={'con-settings-02'} className={classes.background}>
          {showSettings === SettingMenuTypes.Full && (
            <>
              {userSettings && (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.titleInfoContainer}
                >
                  <div className={classes.personIconContainer}>
                    <div className={classes.personIcon}>
                      <div
                        className={
                          userProfileClasses.peopleProfilepic +
                          ' ' +
                          userProfileClasses.defaultProfilepic
                        }
                      />
                    </div>
                  </div>
                  <Typography
                    align="center"
                    variant="h6"
                    className={classes.titleInfoText}
                  >
                    <FormattedMessage
                      id="settings_hello"
                      defaultMessage="Hello"
                    />
                    , {userName}
                  </Typography>
                  <Typography
                    className={classes.userInfoDescription}
                    align="center"
                    variant="body1"
                  >
                    <FormattedMessage
                      id="user_settings_description"
                      defaultMessage="..."
                    />
                  </Typography>
                </Box>
              )}
              <Typography variant="h6" className={classes.sectionTitle}>
                <FormattedMessage
                  id="settings_general"
                  defaultMessage="General"
                />
              </Typography>
              <Paper>
                <List
                  component="nav"
                  className={classes.list}
                  disablePadding={true}
                >
                  <ListItem
                    key="enableNotifications"
                    className={classes.listItem}
                  >
                    <Grid container direction="row" alignItems="center">
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        className={classes.iconContainer}
                        style={{ alignSelf: 'center' }}
                      >
                        <ListItemIcon className={classes.iconItem}>
                          <SyncIcon />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'settings_enable_notifications',
                            defaultMessage: 'Enable Notifications'
                          })}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.gridSwitchContainer}>
                        <Switch
                          disabled={false}
                          color="primary"
                          checked={enableNotifications}
                          onChange={(event) => {
                            setEnableNotifications(event.target.checked)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Paper>
              <Box className={classes.spacer} />
            </>
          )}
          {showSettings === SettingMenuTypes.Deputy && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.titleInfoContainer}
            >
              <Typography
                align="center"
                variant="h6"
                className={classes.titleInfoText}
              >
                {intl.formatMessage({
                  id: 'settings_manage_deputies_title',
                  defaultMessage: 'Manage Deputies'
                })}
              </Typography>
            </Box>
          )}
          <Typography
            variant="h6"
            className={[
              classes.sectionTitle,
              classes.sectionTitleWithButton
            ].join(' ')}
          >
            <FormattedMessage
              id="settings_manage_deputies_label"
              defaultMessage="Deputies"
            />
            <IconButton onClick={() => setShowPeoplePicker(true)}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Typography>
          <Paper>
            <List
              component="nav"
              className={classes.list}
              disablePadding={true}
            >
              {deputies.map((deputy: IDeputy, index: number) => {
                return (
                  <ListItem key={index} className={classes.listItem}>
                    <Grid container direction="row" alignItems="center">
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        className={classes.iconContainer}
                        style={{ alignSelf: 'center' }}
                      >
                        <ListItemIcon className={classes.iconItem}>
                          <PersonIcon />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <ListItemText primary={deputy.displayName} />
                      </Grid>
                      <Grid item xs={2} className={classes.gridSwitchContainer}>
                        <IconButton
                          onClick={() => removeDeputy(deputy.userPrincipalName)}
                        >
                          <HighlightOff />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                )
              })}
            </List>
          </Paper>
          {showSettings === SettingMenuTypes.Full && (
            <>
              <Box className={classes.spacerLogout} />
              <div className={classes.logoutContainer}>
                <Button
                  id={'btn-settings-logout-01'}
                  disableRipple={true}
                  className={classes.logoutBtn}
                  variant="outlined"
                  onClick={() => {
                    if (aadInfo && aadInfo.instance)
                      aadInfo.instance.logoutRedirect()
                  }}
                >
                  <Typography>
                    <FormattedMessage
                      id="settings_button_logout"
                      defaultMessage="Logout"
                    />
                  </Typography>
                </Button>
              </div>
            </>
          )}
        </Box>
        {showPeoplePicker && (
          <PeopleSearch
            aadInfo={aadInfo}
            handleClose={() => {
              setShowPeoplePicker(false)
            }}
            addDeputy={addDeputy}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div className={classes.divTable}>
          <div className={classes.divTableRow}>
            <div className={classes.divTableBuildCell}>
              <Box className={classes.descriptionBuild}>
                <FormattedMessage
                  id="settings_build_info"
                  defaultMessage="BUILD:"
                />
                {buildNumber}
              </Box>
            </div>
            <div className={classes.divTableButtonsCell}>
              <Box>
                <Button
                  id={'btn-settings-cancel-01'}
                  onClick={() => {
                    onCancelButtonClick()
                  }}
                  color="primary"
                  className={classes.cancelButton}
                >
                  <FormattedMessage
                    id="settings_button_cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button
                  id={'btn-settings-save-01'}
                  onClick={() => {
                    onSaveButtonClick()
                  }}
                  color="primary"
                  variant="contained"
                >
                  <FormattedMessage
                    id="settings_button_save"
                    defaultMessage="Save"
                  />
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    pictureUrl: UserSettingsStore.selectors.getPictureUrl(state),
    displayName: UserSettingsStore.selectors.getDisplayName(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (
      userSettings: IUserSetting,
      deputiesChanged: boolean,
      deputiesAdded: IDeputy[],
      deputiesRemoved: IDeputy[]
    ) =>
      dispatch(
        UserSettingsStore.actions.upSertUserSettings(
          userSettings,
          deputiesChanged,
          deputiesAdded,
          deputiesRemoved
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
