import React from 'react'
import { isUrlValid } from 'utils/string'
import { AdsResult } from 'models/AdsResult'
import { PopupDisplayTypes, PopupResult } from 'models/PopupResult'
import { FeaturedResult } from 'models/FeaturedResult'
import {
  AutocompleteGetTagProps,
  InputAdornment,
  TextField
} from '@mui/material'
import { Autocomplete } from '@mui/lab'
import FilterListIcon from '@mui/icons-material/FilterList'
import dayjs from 'dayjs'
import AdminTableTooltipClip from 'components/admin/common/AdminTableTooltipClip'
import { debounceCall } from 'utils/debounce'
import { ContactUsTile } from 'models/ContactUsTile'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import { DidYouMeanItem } from 'models/DidYouMean'
import { requestItemMaxValues } from 'constants/constants'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'

export const editFormErrorKeys = {
  linkText: 'link_text_error',
  link: 'link_error',
  source: 'source_error',
  image: 'image_error',
  rank: 'rank_error',
  language: 'language_error',
  title: 'title_error',
  display: 'display_error',
  onceEverySession: 'once_every_session_error',
  oncePerInterval: 'once_per_interval_error',
  btnText: 'button_text_error',
  description: 'description_error',
  startDate: 'startdate_error',
  endDate: 'enddate_error',
  searchTerms: 'search_terms_error',
  url: 'url_error',
  site_url: 'site_url_error',
  order: 'order_error',
  original: 'original_error',
  corrected: 'corrected_error',
  upn: 'upn_error'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertToArray = (value = '') => {
  if (typeof value !== 'string') {
    return value
  }
  return value
    .split(',')
    .map((v) => v.trim())
    .filter(Boolean)
}

/**
 * Check is given item AdsResult
 * @param toBeDetermined
 * @returns
 */
export function determineIsAdsResult(
  toBeDetermined: AdsResult | PopupResult | ContactUsTile
): toBeDetermined is AdsResult {
  if (toBeDetermined && (toBeDetermined as AdsResult).text !== undefined) {
    return true
  }
  return false
}

/**
 * Check is given item ContactUsTile
 * @param toBeDetermined
 * @returns
 */
export function determineIsContactUsTile(
  toBeDetermined: AdsResult | PopupResult | ContactUsTile
): toBeDetermined is ContactUsTile {
  if ((toBeDetermined as ContactUsTile).description !== undefined) {
    return true
  }
  return false
}

export const findAdChild = (
  adParent: AdsResult,
  adList: AdsResult[]
): AdsResult[] => {
  return adList.filter(
    (adListItem: AdsResult) =>
      adListItem.id === adParent.id && adListItem.language !== adParent.language
  )
}

export const findPopupChild = (
  popupParent: PopupResult,
  popupList: PopupResult[]
): PopupResult[] => {
  return popupList.filter(
    (popupListItem: PopupResult) =>
      popupListItem.id === popupParent.id &&
      popupListItem.language !== popupParent.language
  )
}

export const findContactUsChild = (
  contactUsParent: ContactUsTile,
  contactUsList: ContactUsTile[]
): ContactUsTile[] => {
  return contactUsList.filter(
    (contactUsItem: ContactUsTile) =>
      contactUsItem.id === contactUsParent.id &&
      contactUsItem.language !== contactUsParent.language
  )
}

/**
 * Render Dropdown for admin forms
 * @param selectedValue get state
 * @param setSelectedValue set state
 * @param options option list
 * @param multiselect is multiselect
 * @param label label name
 * @param type property name
 * @param currentObject current AdsResult or PopupResult object
 * @param isNewItem is new item
 * @param onChange onchange callback
 * @param freeSolo allow free values
 * @param error error object
 * @returns
 */
export const renderSelect = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectedValue: any,
  setSelectedValue: (value: any) => void,
  options: any[],
  multiselect: boolean,
  label: string,
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  currentObject: any,
  isNewItem: boolean,
  onChange: () => void,
  freeSolo?: boolean,
  error?: IErrorObject,
  disabled?: boolean,
  className?: string
): JSX.Element => (
  <Autocomplete
    className={className}
    multiple={multiselect}
    id="size-small-outlined-multi"
    size="small"
    options={options}
    freeSolo={freeSolo}
    style={!className ? { marginBottom: '10px' } : {}}
    getOptionLabel={
      propertyName === 'language' ||
      propertyName === 'sources' ||
      propertyName === 'BestBetDataSources'
        ? (option: any) => option.name
        : (option: any) => option
    }
    isOptionEqualToValue={
      propertyName === 'language'
        ? (option, value) => {
            return option.locale === value.locale
          }
        : propertyName === 'sources' || propertyName === 'BestBetDataSources'
        ? (option, value) => {
            return option.key === value.key
          }
        : (option, value) => {
            return option === value
          }
    }
    renderTags={(
      value: any,
      getTagProps: AutocompleteGetTagProps
    ): React.ReactNode => {
      let isAllSet = false

      if (propertyName !== 'language') {
        if (
          propertyName === 'sources' ||
          propertyName === 'BestBetDataSources'
        ) {
          isAllSet = !!value.find((item: any) =>
            item.key
              ? item.key.toLocaleLowerCase() === 'all'
              : item.toLocaleLowerCase() === 'all'
          )
        } else {
          isAllSet = !!value.find(
            (item: string) => item.toLocaleLowerCase() === 'all'
          )
        }
      }

      return value.map((item: any, index: number) => {
        let content = item.name ? item.name : item

        if (isAllSet && content.toLocaleLowerCase() !== 'all')
          content = '!= ' + content

        return (
          <>
            <AdminTableTooltipClip
              size="small"
              label={content}
              key={index}
              additionalProps={getTagProps({ index })}
            />
          </>
        )
      })
    }}
    onChange={(event, value, reason) => {
      setSelectedValue(value)

      if (propertyName === 'language') {
        currentObject[propertyName] = isNewItem
          ? value.length > 0
            ? value[0].locale
            : ''
          : value.locale
      } else if (multiselect) {
        if (
          propertyName === 'sources' ||
          propertyName === 'BestBetDataSources'
        ) {
          value = value.map((item: any) => (item.key ? item.key : item))
        }

        currentObject[propertyName] = value ? convertToArray(value) : []
      } else {
        currentObject[propertyName] = value
      }

      onChange()
      return true
    }}
    disabled={disabled || (!isNewItem && propertyName === 'language')}
    disableClearable={true}
    value={selectedValue}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        error={error && error.error ? error.error : false}
        helperText={error && error.error ? error.helperText : ''}
        InputLabelProps={{
          shrink: true
        }}
      />
    )}
  />
)

export const findDefaultFilterComponent = (props: any) => {
  const { columnDef, onFilterChanged } = props

  const id: string = (columnDef as any).tableData.id
  return (
    <TextField
      variant="standard"
      value={columnDef.defaultFilter}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterListIcon />
          </InputAdornment>
        )
      }}
      onChange={(e) => {
        debounceCall(
          (val: any) => {
            onFilterChanged(val.id, val.value)
          },
          { id: id, value: e.target.value },
          500
        )
      }}
    />
  )
}

//validations
export const checkIsValidAd = (
  adItem: AdsResult,
  enItem: AdsResult,
  isSelfServiceValidation: boolean
): IErrorListObject[] => {
  const titleValid = validateTitle(adItem.title, adItem.language)
  const descriptionValid = validateDescription(adItem.text, adItem.language)

  const sourceValid = validateSource(enItem.sources, adItem.language)
  const rankValid = validateRank(enItem.rank, enItem.language)
  const imageValid = validateImage(enItem)
  const startDateValid = validateStartDate(enItem.start)
  const endDateValid = validateEndDate(enItem.end, isSelfServiceValidation)
  const linkTextValid = validateLinkText(enItem)
  const languageValid = validateLanguage(adItem)
  const urlValid = validateUrl(enItem)
  const siteUrlValid = validateSiteUrlValid(enItem)
  const isDateSpanValid = validateDateSpan(enItem.start, enItem.end)

  return [
    ...titleValid,
    ...descriptionValid,
    ...sourceValid,
    ...rankValid,
    ...imageValid,
    ...startDateValid,
    ...endDateValid,
    ...linkTextValid,
    ...languageValid,
    ...urlValid,
    ...siteUrlValid,
    ...isDateSpanValid
  ]
}

export const checkIsValidContactUsTile = (
  cusItem: ContactUsTile,
  enItem: ContactUsTile,
  allContactUsTiles: ContactUsTile[]
): IErrorListObject[] => {
  const titleValid = validateTitle(cusItem.title, cusItem.language)
  const descriptionValid = validateDescription(
    cusItem.description,
    cusItem.language
  )
  const imageValid = validateImage(enItem)
  const languageValid = validateLanguage(cusItem)
  const urlValid = validateUrlContactUsTile(enItem)
  const orderValid = validateOrder(cusItem, allContactUsTiles)
  return [
    ...titleValid,
    ...descriptionValid,
    ...imageValid,
    ...languageValid,
    ...urlValid,
    ...orderValid
  ]
}

export const checkIsValidDidYouMeanItem = (
  dymItem: DidYouMeanItem
): IErrorListObject[] => {
  const termValid = validateTerm(dymItem.term)

  return [...termValid]
}

export const checkIsValidNewsLetterSubscription = (
  subsciptionItem: INewsLetterSubscription
): IErrorListObject[] => {
  let upnValid = [
    {
      locale: 'en',
      key: editFormErrorKeys.upn,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]

  if (!subsciptionItem.upn) {
    upnValid = [
      {
        locale: 'en',
        key: editFormErrorKeys.upn,
        error: {
          error: true,
          helperText: 'The User email must be defined'
        }
      }
    ]
  } else {
    subsciptionItem.upn.split(',').forEach((upn: string) => {
      const valid = upn.match(/^[\w.]+@[\w.]+$/g)
      if (!valid) {
        upnValid = [
          {
            locale: 'en',
            key: editFormErrorKeys.upn,
            error: {
              error: true,
              helperText: 'Please enter a valid user email'
            }
          }
        ]
      }
    })
  }

  return [...upnValid]
}

export const checkIsValidPopup = (
  popupItem: PopupResult,
  enItem: PopupResult
): IErrorListObject[] => {
  const titleValid = validateTitle(popupItem.title, popupItem.language)
  const sourceValid = validateSource(enItem.sources, popupItem.language)
  const displayValid = validateDisplay(popupItem)
  const onceEverySessionValid = validateOnceEverySession(popupItem)
  const oncePerIntervalValid = validateOncePerInterval(popupItem)
  const btnTextValid = validateButtonText(popupItem)
  const languageValid = validateLanguage(popupItem)
  const urlValid = validateUrl(popupItem)

  return [
    ...titleValid,
    ...sourceValid,
    ...displayValid,
    ...onceEverySessionValid,
    ...oncePerIntervalValid,
    ...btnTextValid,
    ...languageValid,
    ...urlValid
  ]
}

export const checkIsFeaturedResultValid = (
  featuredResult: FeaturedResult,
  isSelfServiceValidation: boolean
): IErrorListObject[] => {
  const titleValid = validateTitle(featuredResult.BestBetTitle)
  const sourceValid = validateSource(featuredResult.BestBetDataSources)
  const descriptionValid = validateDescription(
    featuredResult.BestBetDescription
  )
  const rankValid = validateRank(featuredResult.BestBetRank)
  const linkValid = validateLink(featuredResult.BestBetUrl)
  const startDateValid = validateStartDate(featuredResult.BestBetStartDate)
  const endDateValid = validateEndDate(
    featuredResult.BestBetEndDate,
    isSelfServiceValidation
  )
  const searchTermsValid = validateSearchTerms(featuredResult.BestBetKeywords)
  const urlValid = validateUrlString(
    featuredResult.BestBetUrl,
    editFormErrorKeys.url,
    'Url is not valid'
  )
  const siteUrlValid = validateUrlString(
    featuredResult.BestBetSiteUrl,
    editFormErrorKeys.site_url,
    'Site-Url is not valid'
  )
  const isDateSpanValid = validateDateSpan(
    featuredResult.BestBetStartDate,
    featuredResult.BestBetEndDate
  )

  return [
    ...titleValid,
    ...sourceValid,
    ...descriptionValid,
    ...rankValid,
    ...linkValid,
    ...startDateValid,
    ...endDateValid,
    ...searchTermsValid,
    ...urlValid,
    ...siteUrlValid,
    ...isDateSpanValid
  ]
}

export const checkIsAdFormStepValid = (
  adItem: AdsResult,
  enItem: AdsResult,
  intl: any,
  formStep?: number
): IErrorListObject[] => {
  if (formStep === undefined) {
    const titleValid = validateTitle(
      adItem.title,
      adItem.language,
      intl.formatMessage({
        id: 'from_validation_title',
        defaultMessage: 'Please enter the headline.'
      }),
      intl.formatMessage({
        id: 'from_validation_title_length',
        defaultMessage: `The headline exceeds the maximum length of {maxLength} characters.`
      })
    )
    const descriptionValid = validateDescription(
      adItem.text,
      adItem.language,
      intl.formatMessage({
        id: 'form_validation_description',
        defaultMessage: 'Please enter the body text.'
      }),
      intl.formatMessage({
        id: 'form_validation_description_length',
        defaultMessage: `The body text exceeds the maximum length of {maxLength} characters.`
      })
    )
    const linkTextValid = validateLinkText(
      enItem,
      intl.formatMessage({
        id: 'form_validation_link',
        defaultMessage:
          'In case a link url is added, the text of the link has to be defined.'
      }),
      intl.formatMessage({
        id: 'form_validation_link_text',
        defaultMessage:
          'In case a link text is added, the link has to be defined.'
      })
    )
    const urlValid = validateUrl(
      enItem,
      intl.formatMessage({
        id: 'form_validation_link_url',
        defaultMessage: 'Please enter a valid link URL.'
      })
    )
    const startDateValid = validateStartDate(
      enItem.start,
      'en',
      intl.formatMessage({
        id: 'form_validation_startdate',
        defaultMessage: 'Please select a start date.'
      })
    )
    const endDateValid = validateEndDate(
      enItem.end,
      true,
      'en',
      intl.formatMessage({
        id: 'form_validation_enddate',
        defaultMessage: 'Please select an expiration date.'
      }),
      intl.formatMessage({
        id: 'form_validation_enddate_expired',
        defaultMessage: 'Expiration Date must be greater than today.'
      }),
      intl.formatMessage({
        id: 'form_validation_enddate_period',
        defaultMessage:
          'Maximum time period allowed is 1 year. Requests can be extended when 1 year is reached'
      })
    )
    const imageValid = validateImage(
      enItem,
      intl.formatMessage({
        id: 'form_validation_image_missing',
        defaultMessage: 'In case of a large AdWord an image must be added.'
      })
    )
    const isDateSpanValid = validateDateSpan(
      enItem.start,
      enItem.end,
      'en',
      intl.formatMessage({
        id: 'form_validation_datespan',
        defaultMessage:
          'Please select an expiration date greater than the start date.'
      })
    )
    const searchTermValid = validateSearchTerms(
      enItem.search_terms,
      enItem.language,
      intl.formatMessage({
        id: 'form_validation_searchterms_adword',
        defaultMessage:
          'Please enter at least one keyword that trigger the AdWord.'
      }),
      intl.formatMessage({
        id: 'form_validation_searchterms_length',
        defaultMessage:
          'The number of entered keywords exceeds the defined total amount of {maxLength}.'
      })
    )
    const sourceValid = validateSource(
      enItem.sources,
      adItem.language,
      intl.formatMessage({
        id: 'form_validation_source_adword',
        defaultMessage:
          'Please select a search vertical where to show the AdWord.'
      })
    )
    const siteUrlValid = validateSiteUrlValid(
      enItem,
      intl.formatMessage({
        id: 'form_validation_siteurl',
        defaultMessage: 'Please enter a valid OneIntranet site url.'
      })
    )

    return [
      ...titleValid,
      ...descriptionValid,
      ...linkTextValid,
      ...urlValid,
      ...startDateValid,
      ...endDateValid,
      ...searchTermValid,
      ...isDateSpanValid,
      ...imageValid,
      ...sourceValid,
      ...siteUrlValid
    ]
  } else if (formStep === 0) {
    const titleValid = validateTitle(
      adItem.title,
      adItem.language,
      intl.formatMessage({
        id: 'from_validation_title',
        defaultMessage: 'Please enter the headline.'
      }),
      intl.formatMessage({
        id: 'from_validation_title_length',
        defaultMessage: `The headline exceeds the maximum length of {maxLength} characters.`
      })
    )
    const descriptionValid = validateDescription(
      adItem.text,
      adItem.language,
      intl.formatMessage({
        id: 'form_validation_description',
        defaultMessage: 'Please enter the body text.'
      }),
      intl.formatMessage({
        id: 'form_validation_description_length',
        defaultMessage: `The body text exceeds the maximum length of {maxLength} characters.`
      })
    )
    const linkTextValid = validateLinkText(
      enItem,
      intl.formatMessage({
        id: 'form_validation_link',
        defaultMessage:
          'In case a link url is added, the text of the link has to be defined.'
      }),
      intl.formatMessage({
        id: 'form_validation_link_text',
        defaultMessage:
          'In case a link text is added, the link has to be defined.'
      })
    )
    const urlValid = validateUrl(
      enItem,
      intl.formatMessage({
        id: 'form_validation_link_url',
        defaultMessage: 'Please enter a valid link URL.'
      })
    )

    return [...titleValid, ...descriptionValid, ...linkTextValid, ...urlValid]
  } else if (formStep === 2) {
    const titleValid = validateTitle(
      adItem.title,
      adItem.language,
      intl.formatMessage({
        id: 'from_validation_title_translation',
        defaultMessage: 'Please enter the headline of the translation.'
      }),
      intl.formatMessage({
        id: 'from_validation_title_translation_length',
        defaultMessage:
          'The headline of the translation exceeds the maximum length of {maxLength} characters.'
      })
    )
    const descriptionValid = validateDescription(
      adItem.text,
      adItem.language,
      intl.formatMessage({
        id: 'form_validation_description_translation',
        defaultMessage: 'Please enter the body text of the translation.'
      }),
      intl.formatMessage({
        id: 'form_validation_description_translation_length',
        defaultMessage: `The body text of the translation exceeds the maximum length of {maxLength} characters.`
      })
    )
    const linkTextValid = validateLinkTextTranslation(
      adItem,
      enItem,
      intl.formatMessage({
        id: 'form_validation_link_translation',
        defaultMessage: 'Please add the link text of the of the translation.'
      })
    )

    return [...titleValid, ...descriptionValid, ...linkTextValid]
  } else if (formStep === 1) {
    const startDateValid = validateStartDate(
      enItem.start,
      'en',
      intl.formatMessage({
        id: 'form_validation_startdate',
        defaultMessage: 'Please select a start date.'
      })
    )
    const endDateValid = validateEndDate(
      enItem.end,
      true,
      'en',
      intl.formatMessage({
        id: 'form_validation_enddate',
        defaultMessage: 'Please select an expiration date.'
      }),
      intl.formatMessage({
        id: 'form_validation_enddate_expired',
        defaultMessage: 'Expiration Date must be greater than today.'
      }),
      intl.formatMessage({
        id: 'form_validation_enddate_period',
        defaultMessage:
          'Maximum time period allowed is 1 year. Requests can be extended when 1 year is reached.'
      })
    )
    const imageValid = validateImage(
      enItem,
      intl.formatMessage({
        id: 'form_validation_image_missing',
        defaultMessage: 'In case of a large AdWord an image must be added.'
      })
    )
    const isDateSpanValid = validateDateSpan(
      enItem.start,
      enItem.end,
      'en',
      intl.formatMessage({
        id: 'form_validation_datespan',
        defaultMessage:
          'Please select an expiration date greater than the start date.'
      })
    )
    const searchTermValid = validateSearchTerms(
      enItem.search_terms,
      enItem.language,
      intl.formatMessage({
        id: 'form_validation_searchterms_adword',
        defaultMessage:
          'Please enter at least one keyword that trigger the AdWord.'
      }),
      intl.formatMessage({
        id: 'form_validation_searchterms_length',
        defaultMessage:
          'The number of entered keywords exceeds the defined total amount of {maxLength}.'
      })
    )

    return [
      ...startDateValid,
      ...endDateValid,
      ...searchTermValid,
      ...isDateSpanValid,
      ...imageValid
    ]
  } else if (formStep === 3) {
    const sourceValid = validateSource(
      enItem.sources,
      adItem.language,
      intl.formatMessage({
        id: 'form_validation_source_adword',
        defaultMessage:
          'Please select a search vertical where to show the AdWord.'
      })
    )
    const siteUrlValid = validateSiteUrlValid(
      enItem,
      intl.formatMessage({
        id: 'form_validation_siteurl',
        defaultMessage: 'Please enter a valid OneIntranet site url.'
      })
    )

    return [...sourceValid, ...siteUrlValid]
  }

  return []
}

export const checkIsFeaturedResultFormStepValid = (
  featuredResult: FeaturedResult,
  intl: any,
  formStep?: number
): IErrorListObject[] => {
  if (formStep === undefined) {
    const titleValid = validateTitle(featuredResult.BestBetTitle, 'en')
    const linkValid = validateLink(featuredResult.BestBetUrl, 'en')
    const descriptionValid = validateDescription(
      featuredResult.BestBetDescription,
      'en'
    )
    const urlValid = validateUrlString(
      featuredResult.BestBetUrl,
      editFormErrorKeys.url,
      ''
    )
    const startDateValid = validateStartDate(
      featuredResult.BestBetStartDate,
      'en'
    )
    const endDateValid = validateEndDate(
      featuredResult.BestBetEndDate,
      true,
      'en'
    )
    const searchTermsValid = validateSearchTerms(
      featuredResult.BestBetKeywords,
      'en'
    )
    const isDateSpanValid = validateDateSpan(
      featuredResult.BestBetStartDate,
      featuredResult.BestBetEndDate,
      'en'
    )
    const sourceValid = validateSource(featuredResult.BestBetDataSources, 'en')
    const siteUrlValid = validateUrlString(
      featuredResult.BestBetSiteUrl,
      editFormErrorKeys.site_url,
      ''
    )

    return [
      ...titleValid,
      ...descriptionValid,
      ...linkValid,
      ...urlValid,
      ...startDateValid,
      ...endDateValid,
      ...searchTermsValid,
      ...isDateSpanValid,
      ...sourceValid,
      ...siteUrlValid
    ]
  } else if (formStep === 0) {
    const titleValid = validateTitle(
      featuredResult.BestBetTitle,
      'en',
      intl.formatMessage({
        id: 'from_validation_title',
        defaultMessage: 'Please enter the headline.'
      }),
      intl.formatMessage({
        id: 'from_validation_title_length',
        defaultMessage: `The headline exceeds the maximum length of {maxLength} characters.`
      })
    )
    const linkValid = validateLink(
      featuredResult.BestBetUrl,
      'en',
      intl.formatMessage({
        id: 'form_validation_link_required',
        defaultMessage: 'Please enter a link for the feature result.'
      })
    )
    const descriptionValid = validateDescription(
      featuredResult.BestBetDescription,
      'en',
      intl.formatMessage({
        id: 'form_validation_description',
        defaultMessage: 'Please enter the body text.'
      }),
      intl.formatMessage({
        id: 'form_validation_description_length',
        defaultMessage: `The body text exceeds the maximum length of {maxLength} characters.`
      })
    )
    const urlValid = validateUrlString(
      featuredResult.BestBetUrl,
      editFormErrorKeys.url,
      intl.formatMessage({
        id: 'form_validation_link_url',
        defaultMessage: 'Please enter a valid link URL.'
      })
    )

    return [...titleValid, ...descriptionValid, ...linkValid, ...urlValid]
  } else if (formStep === 1) {
    const startDateValid = validateStartDate(
      featuredResult.BestBetStartDate,
      'en',
      intl.formatMessage({
        id: 'form_validation_startdate',
        defaultMessage: 'Please select a start date.'
      })
    )
    const endDateValid = validateEndDate(
      featuredResult.BestBetEndDate,
      true,
      'en',
      intl.formatMessage({
        id: 'form_validation_enddate',
        defaultMessage: 'Please select an expiration date.'
      }),
      intl.formatMessage({
        id: 'form_validation_enddate_expired',
        defaultMessage: 'Expiration Date must be greater than today.'
      }),
      intl.formatMessage({
        id: 'form_validation_enddate_period',
        defaultMessage:
          'Maximum time period allowed is 1 year. Requests can be extended when 1 year is reached'
      })
    )
    const searchTermsValid = validateSearchTerms(
      featuredResult.BestBetKeywords,
      'en',
      intl.formatMessage({
        id: 'form_validation_searchterms_featured',
        defaultMessage:
          'Please enter at least one keyword that trigger the Featured Result.'
      }),
      intl.formatMessage({
        id: 'form_validation_searchterms_length',
        defaultMessage:
          'The number of entered keywords exceeds the defined total amount of {maxLength}.'
      })
    )
    const isDateSpanValid = validateDateSpan(
      featuredResult.BestBetStartDate,
      featuredResult.BestBetEndDate,
      'en',
      intl.formatMessage({
        id: 'form_validation_datespan',
        defaultMessage:
          'Please select an expiration date greater than the start date.'
      })
    )

    return [
      ...startDateValid,
      ...endDateValid,
      ...searchTermsValid,
      ...isDateSpanValid
    ]
  } else if (formStep === 2) {
    const sourceValid = validateSource(
      featuredResult.BestBetDataSources,
      'en',
      intl.formatMessage({
        id: 'form_validation_source_featured',
        defaultMessage:
          'Please select a search vertical where to show the Featured Result.'
      })
    )
    const siteUrlValid = validateUrlString(
      featuredResult.BestBetSiteUrl,
      editFormErrorKeys.site_url,
      intl.formatMessage({
        id: 'form_validation_siteurl',
        defaultMessage: 'Please enter a valid OneIntranet site url.'
      })
    )

    return [...sourceValid, ...siteUrlValid]
  }

  return []
}

const validateLinkText = (
  item: AdsResult | PopupResult | ContactUsTile,
  customErrorTextLink?: string,
  customErrorTextLinkText?: string
): IErrorListObject[] => {
  if (
    (determineIsAdsResult(item) && item.link && !item.link_text) ||
    (determineIsContactUsTile(item) && !item.link)
  ) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.linkText,
        error: {
          error: true,
          helperText: customErrorTextLink
            ? customErrorTextLink
            : 'Link text must be defined'
        }
      }
    ]
  }

  if (determineIsAdsResult(item) && !item.link && item.link_text) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.link,
        error: {
          error: true,
          helperText: customErrorTextLinkText
            ? customErrorTextLinkText
            : 'Link must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.link,
      error: {
        error: false,
        helperText: ''
      }
    },
    {
      locale: item.language,
      key: editFormErrorKeys.linkText,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateLinkTextTranslation = (
  languageItem: AdsResult,
  enItem: AdsResult,
  customErrorText?: string
) => {
  if (enItem.link && !languageItem.link_text) {
    return [
      {
        locale: languageItem.language,
        key: editFormErrorKeys.linkText,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'Link text must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: languageItem.language,
      key: editFormErrorKeys.linkText,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateSource = (
  sources: string[],
  language?: string,
  customErrorText?: string
): IErrorListObject[] => {
  if (!sources || sources.length < 1) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.source,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'At least one source must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.source,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateImage = (
  item: AdsResult | PopupResult | ContactUsTile,
  customErrorText?: string
): IErrorListObject[] => {
  if (
    (determineIsAdsResult(item) && item.flavour === 'large' && !item.image) ||
    (determineIsContactUsTile(item) && !item.image)
  ) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.image,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'Image must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.image,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateRank = (
  rank: number | null,
  language?: string
): IErrorListObject[] => {
  if (rank && rank < 0) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.rank,
        error: {
          error: true,
          helperText: 'Rank must be 0 or higher'
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.rank,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateLanguage = (
  item: AdsResult | PopupResult | ContactUsTile
): IErrorListObject[] => {
  if (!item.language) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.language,
        error: {
          error: true,
          helperText: 'Language must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.language,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateTitle = (
  title: string,
  language?: string,
  customErrorText?: string,
  customLengthValidationText?: string
): IErrorListObject[] => {
  if (!title) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.title,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'Title must be defined'
        }
      }
    ]
  } else if (
    title.length > requestItemMaxValues.title &&
    customLengthValidationText
  ) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.title,
        error: {
          error: true,
          helperText: customLengthValidationText.replace(
            '{maxLength}',
            requestItemMaxValues.title.toString()
          )
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.title,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateDisplay = (item: PopupResult): IErrorListObject[] => {
  if (!determineIsAdsResult(item) && !item.display) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.display,
        error: {
          error: true,
          helperText: 'It must be specified when the popup should be displayed.'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.display,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateOnceEverySession = (item: PopupResult): IErrorListObject[] => {
  if (
    !determineIsAdsResult(item) &&
    item.display === PopupDisplayTypes.onEverySession &&
    (!item.dateStart || !item.dateEnd)
  ) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.onceEverySession,
        error: {
          error: true,
          helperText: 'Start and end date must be specified'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.onceEverySession,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateOncePerInterval = (item: PopupResult): IErrorListObject[] => {
  if (
    !determineIsAdsResult(item) &&
    item.display === PopupDisplayTypes.oncePerInterval &&
    (!item.dateStart || !item.dateEnd || !item.intervalHours)
  ) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.oncePerInterval,
        error: {
          error: true,
          helperText: 'It must be specified when the popup should be displayed.'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.oncePerInterval,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateOrder = (
  item: ContactUsTile,
  allContactUsTiles: ContactUsTile[]
): IErrorListObject[] => {
  if (
    determineIsContactUsTile(item) &&
    item.order &&
    allContactUsTiles.find((i) => i.order === item.order && i.id !== item.id)
  ) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.order,
        error: {
          error: true,
          helperText: 'Item with same order already exists'
        }
      }
    ]
  }
  if (determineIsContactUsTile(item) && item.order <= 0) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.order,
        error: {
          error: true,
          helperText: 'Order must be greater than 0'
        }
      }
    ]
  }
  if (isNaN(item.order)) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.order,
        error: {
          error: true,
          helperText: 'Order must be a number greater than 0'
        }
      }
    ]
  }
  return [
    {
      locale: item.language,
      key: editFormErrorKeys.order,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateUrlContactUsTile = (item: ContactUsTile): IErrorListObject[] => {
  if (determineIsContactUsTile(item) && !isUrlValid(item.link)) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.url,
        error: {
          error: true,
          helperText: 'Link is not valid'
        }
      }
    ]
  }
  return [
    {
      locale: item.language,
      key: editFormErrorKeys.url,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

export const validateUrl = (
  item: AdsResult | PopupResult,
  customErrorText?: string
): IErrorListObject[] => {
  if (
    !determineIsAdsResult(item) &&
    item.button_link &&
    !isUrlValid(item.button_link)
  ) {
    //popup
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.url,
        error: {
          error: true,
          helperText: customErrorText ? customErrorText : 'Url is not valid'
        }
      }
    ]
  } else {
    //adWord
    if (determineIsAdsResult(item) && item.link && !isUrlValid(item.link))
      return [
        {
          locale: item.language,
          key: editFormErrorKeys.url,
          error: {
            error: true,
            helperText: customErrorText ? customErrorText : 'Url is not valid'
          }
        }
      ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.url,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

export const validateSiteUrlValid = (
  item: AdsResult,
  customErrorText?: string
): IErrorListObject[] => {
  if (determineIsAdsResult(item) && item.site_url) {
    return validateUrlString(
      item.site_url,
      editFormErrorKeys.site_url,
      customErrorText ? customErrorText : 'Site-Url is not valid',
      item.language
    )
  }
  return [
    {
      locale: item.language,
      key: editFormErrorKeys.site_url,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

export const validateUrlString = (
  url: string | null,
  key: string,
  errText: string,
  language?: string
): IErrorListObject[] => {
  if (url && !isUrlValid(url)) {
    return [
      {
        locale: language,
        key: key,
        error: {
          error: true,
          helperText: errText
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: key,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateButtonText = (
  item: AdsResult | PopupResult,
  customErrorText?: string
): IErrorListObject[] => {
  if (!determineIsAdsResult(item) && !item.button_text) {
    return [
      {
        locale: item.language,
        key: editFormErrorKeys.btnText,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'It must be specified when the popup should be displayed.'
        }
      }
    ]
  }

  return [
    {
      locale: item.language,
      key: editFormErrorKeys.btnText,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateLink = (
  link: string,
  language?: string,
  customErrorText?: string
): IErrorListObject[] => {
  if (!link) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.link,
        error: {
          error: true,
          helperText: customErrorText ? customErrorText : 'Url must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.link,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateStartDate = (
  startDate: string | null | undefined,
  language?: string,
  customErrorText?: string
): IErrorListObject[] => {
  if (!startDate) {
    return [
      {
        locale: language ? language : 'en',
        key: editFormErrorKeys.startDate,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'Start date must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: language ? language : 'en',
      key: editFormErrorKeys.startDate,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateEndDate = (
  endDate: string | null | undefined,
  validatePeriod: boolean,
  language?: string,
  customErrorText?: string,
  customErrorTextExpired?: string,
  customErrorTextOneYear?: string
): IErrorListObject[] => {
  console.log(
    '### validate enddate',
    dayjs().diff(new Date(endDate ? endDate : new Date()), 'days', true)
  )
  if (!endDate) {
    return [
      {
        locale: language ? language : 'en',
        key: editFormErrorKeys.endDate,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'End date must be defined'
        }
      }
    ]
  } else if (dayjs().diff(new Date(endDate), 'days', true) > 0) {
    return [
      {
        locale: language ? language : 'en',
        key: editFormErrorKeys.endDate,
        error: {
          error: true,
          helperText: customErrorTextExpired
            ? customErrorTextExpired
            : 'End date must be greater than today'
        }
      }
    ]
  } else if (
    validatePeriod &&
    dayjs().diff(new Date(endDate), 'days', true) < -366
  ) {
    return [
      {
        locale: language ? language : 'en',
        key: editFormErrorKeys.endDate,
        error: {
          error: true,
          helperText: customErrorTextOneYear
            ? customErrorTextOneYear
            : 'End date must be in a period of one year'
        }
      }
    ]
  }

  return [
    {
      locale: language ? language : 'en',
      key: editFormErrorKeys.endDate,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}
const validateDateSpan = (
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  language?: string,
  customErrorText?: string
): IErrorListObject[] => {
  if (startDate && endDate) {
    const dateStart = new Date(startDate)
    const dateEnd = new Date(endDate)

    if (dayjs(dateEnd).diff(dateStart, 'days', true) <= 0) {
      return [
        {
          locale: language ? language : 'en',
          key: editFormErrorKeys.endDate,
          error: {
            error: true,
            helperText: customErrorText
              ? customErrorText
              : 'End date must be greater then Start date'
          }
        }
      ]
    }
  }

  return [
    {
      locale: language ? language : 'en',
      key: editFormErrorKeys.endDate,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateSearchTerms = (
  searchTerms: string[],
  language?: string,
  customErrorText?: string,
  customLengthValidationText?: string
): IErrorListObject[] => {
  if (!searchTerms || searchTerms.length < 1) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.searchTerms,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'Search terms must be defined'
        }
      }
    ]
  } else if (
    searchTerms.length > requestItemMaxValues.search_terms &&
    customLengthValidationText
  ) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.searchTerms,
        error: {
          error: true,
          helperText: customLengthValidationText.replace(
            '{maxLength}',
            requestItemMaxValues.search_terms.toString()
          )
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.searchTerms,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateDescription = (
  description: string,
  language?: string,
  customErrorText?: string,
  customLengthValidationText?: string
): IErrorListObject[] => {
  if (!description) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.description,
        error: {
          error: true,
          helperText: customErrorText
            ? customErrorText
            : 'Description must be defined'
        }
      }
    ]
  } else if (
    description.length > requestItemMaxValues.description &&
    customLengthValidationText
  ) {
    return [
      {
        locale: language,
        key: editFormErrorKeys.description,
        error: {
          error: true,
          helperText: customLengthValidationText.replace(
            '{maxLength}',
            requestItemMaxValues.description.toString()
          )
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.description,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}

const validateTerm = (
  term: string,
  language?: string,
  customErrorText?: string
): IErrorListObject[] => {
  if (!term || term === '') {
    return [
      {
        locale: language,
        key: editFormErrorKeys.original,
        error: {
          error: true,
          helperText: customErrorText ? customErrorText : 'Term must be defined'
        }
      }
    ]
  }

  return [
    {
      locale: language,
      key: editFormErrorKeys.original,
      error: {
        error: false,
        helperText: ''
      }
    }
  ]
}
