import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import Ads from 'components/contents/resultpages/Ads'
import CommentAreaForm from 'components/requests/requestForms/CommentAreaForm'
import EditFormAdWordRequest from 'components/requests/requestForms/EditFormAdWordRequest'
import EditFormFeatureResultRequest from 'components/requests/requestForms/EditFormFeatureResultRequest'
import EditFormSpellingSuggestionRequest from 'components/requests/requestForms/EditFormSpellingSuggestionRequest'
import { AdsResult, intialAdsResult } from 'models/AdsResult'
import { initialDidYouMean } from 'models/DidYouMean'
import { intialFeaturedResult } from 'models/FeaturedResult'
import {
  ISelfServiceRequest,
  ISelfServiceUpdateResponse,
  RequestComment,
  RequestStatus,
  RequestType,
  defaultSelfServiceRequest
} from 'models/SelfServiceRequest'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { ESSettingsGlobalVariables } from 'store/ESSettingsGlobalVariables'
import { createAdminRequest } from 'store/SelfService/actions'
import { getStylesAdminCreateRequest } from 'styles/admin/AdminCreateRequest'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import {
  updateBaseProperties,
  updateComments
} from 'utils/admin/selfServiceUtils'
import { isUpnValid } from 'utils/string'

interface IAdminCreateRequestProps {
  setShowCreateRequestDialog: (showCreateRequest: boolean) => void
  showCreateRequestDialog: boolean
}

type IAllAdminCreateRequestProps = ReturnType<typeof mapDispatchToProps> &
  IAdminCreateRequestProps

function AdminCreateRequest(props: IAllAdminCreateRequestProps): JSX.Element {
  const {
    setShowCreateRequestDialog,
    showCreateRequestDialog,
    createAdminRequestLocal
  } = props

  const [upn, setUpn] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [requestType, setRequestType] = useState<RequestType>(
    RequestType.FeaturedResult
  )
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<ISelfServiceUpdateResponse | null>(
    null
  )
  const [referenceRowData, setRefereneRowData] = useState<ISelfServiceRequest>(
    defaultSelfServiceRequest
  )
  const [newDialogOpen, setNewDialogOpen] = useState(false)
  const [showAdsPreview, setShowAdsPreview] = useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [isValid, setIsValid] = useState(false)
  const [newComment, setNewComment] = useState<RequestComment>({
    id: 1,
    text: '',
    modifiedDate: new Date().toISOString(),
    modifiedBy: ESSettingsGlobalVariables.getUPN(),
    modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
  })

  const classes = getStylesAdminCreateRequest()
  const classesForm = getStylesAdminForm()

  const handleCreateConfirm = (publishItem: boolean) => {
    if (publishItem) {
      referenceRowData.status = RequestStatus.Published
      referenceRowData.oncePublished = true
    } else {
      referenceRowData.status = RequestStatus.Submitted
      referenceRowData.oncePublished = false
    }

    setShowConfirmDialog(true)
  }

  const handleCreateItem = () => {
    const deepCopy: ISelfServiceRequest = JSON.parse(
      JSON.stringify(defaultSelfServiceRequest)
    )

    deepCopy.requestType = requestType
    deepCopy.upn = upn
    deepCopy.createdBy = displayName
    deepCopy.createdDate = new Date().toISOString()
    deepCopy.modifiedDate = new Date().toISOString()
    deepCopy.modifiedBy = ESSettingsGlobalVariables.getDisplayName()
    switch (requestType) {
      case RequestType.AdWord:
        deepCopy.itemData = [Object.assign({}, intialAdsResult)]
        break
      case RequestType.FeaturedResult:
        deepCopy.itemData = Object.assign({}, intialFeaturedResult)
        break
      case RequestType.SpellingSuggestion:
        deepCopy.itemData = Object.assign({}, initialDidYouMean)
        break
    }

    setRefereneRowData(deepCopy)
    setNewComment({
      id: 1,
      text: '',
      modifiedDate: new Date().toISOString(),
      modifiedBy: ESSettingsGlobalVariables.getUPN(),
      modifiedByDisplayName: ESSettingsGlobalVariables.getDisplayName()
    })
    setNewDialogOpen(true)
  }

  const handleSave = () => {
    setShowConfirmDialog(false)
    setNewDialogOpen(false)
    setLoading(true)

    if (referenceRowData.requestType === RequestType.AdWord) {
      const adItemList = referenceRowData.itemData as AdsResult[]
      const newItemList: AdsResult[] = []
      adItemList.forEach((item: AdsResult) => {
        newItemList.push({
          ...adItemList[0],
          ...{
            language: item.language,
            title: item.title ? item.title : adItemList[0].title,
            text: item.text ? item.text : adItemList[0].text,
            link_text: item.link_text
              ? item.link_text
              : adItemList[0].link_text,
            countries:
              item.countries.length === 0 ? ['All'] : adItemList[0].countries,
            functions:
              item.functions.length === 0 ? ['All'] : adItemList[0].functions
          }
        })
      })

      referenceRowData.itemData = newItemList

      const enItem = referenceRowData.itemData.find(
        (adWord: AdsResult) => adWord.language === 'en'
      )

      if (enItem) {
        referenceRowData.itemData.forEach((adWord: AdsResult) => {
          if (adWord.language !== 'en' && adWord.title !== enItem.title) {
            referenceRowData.definedLanguages.push(adWord.language)
          }
        })
      }
    }

    referenceRowData.modifiedDate = new Date().toISOString()
    referenceRowData.modifiedBy = ESSettingsGlobalVariables.getDisplayName()
    referenceRowData.id = 'new'

    updateBaseProperties(referenceRowData)
    updateComments(referenceRowData, newComment)

    createAdminRequestLocal(referenceRowData).then(
      (responseResult: ISelfServiceUpdateResponse) => {
        setLoading(false)
        setResponse(responseResult)
      }
    )
  }

  const getDialogTitle = (): string => {
    switch (referenceRowData.requestType) {
      case RequestType.AdWord:
        return `New Adword for ${upn}`
      case RequestType.FeaturedResult:
        return `New Featured Result for ${upn}`
      case RequestType.SpellingSuggestion:
        return `New 'Did You Mean' Suggestion for ${upn}`
    }
  }

  const getDialogContent = () => {
    switch (referenceRowData.requestType) {
      case RequestType.AdWord:
        return (
          <>
            <EditFormAdWordRequest
              item={referenceRowData}
              isNewItem={true}
              setItemList={updateAdWordItemDataInRequest}
              setIsValid={setIsValid}
              setHasImageChange={() => {}}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
            <CommentAreaForm item={referenceRowData} newComment={newComment} />
          </>
        )
      case RequestType.FeaturedResult:
        return (
          <>
            <EditFormFeatureResultRequest
              item={referenceRowData}
              isNewItem={true}
              setIsValid={setIsValid}
            />
            <CommentAreaForm item={referenceRowData} newComment={newComment} />
          </>
        )
      case RequestType.SpellingSuggestion:
        return (
          <EditFormSpellingSuggestionRequest
            item={referenceRowData}
            setIsValid={setIsValid}
          />
        )
      default:
        return <></>
    }
  }

  const updateAdWordItemDataInRequest = (newList: AdsResult[]) => {
    referenceRowData.itemData = newList
    setRefereneRowData(referenceRowData)
  }

  const getPreviewAdItem = (): AdsResult => {
    if (
      referenceRowData.itemData &&
      Array.isArray(referenceRowData.itemData) &&
      referenceRowData.itemData.length >= tabIndex + 1
    ) {
      const ad = Object.assign(
        {},
        (referenceRowData.itemData as AdsResult[])[tabIndex]
      )

      ad.image = referenceRowData.imagePreview
        ? `data:${referenceRowData.imagePreview}`
        : ''

      return ad
    } else {
      return intialAdsResult
    }
  }

  return (
    <>
      {showCreateRequestDialog && (
        <Dialog
          open={showCreateRequestDialog}
          onClose={() => setShowCreateRequestDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="admin-create-request-tile">
            {response
              ? `Created request`
              : `Create a requests on behalf of a user`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ position: 'relative', height: '210px' }}>
                {!response && (
                  <>
                    {!loading && (
                      <>
                        <TextField
                          variant="standard"
                          id="upn-required"
                          className={classes.tab_item}
                          label="upn *"
                          defaultValue={upn}
                          onChange={(event) => {
                            setUpn(event.target.value)
                          }}
                          error={isUpnValid(upn) ? false : true}
                          helperText={
                            isUpnValid(upn) ? '' : 'Invalid upn format'
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        <TextField
                          variant="standard"
                          id="displayname-required"
                          className={classes.tab_item}
                          label="display name *"
                          defaultValue={displayName}
                          onChange={(event) => {
                            setDisplayName(event.target.value)
                          }}
                          error={!displayName}
                          helperText={
                            !displayName ? 'display name is required' : ''
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                        <FormControl
                          style={{ marginTop: '10px' }}
                          fullWidth
                          size="small"
                        >
                          <InputLabel id="select-label">RequestType</InputLabel>
                          <Select
                            id="requesttypeselect"
                            multiple={false}
                            variant={'outlined'}
                            defaultValue="FeaturedResult"
                            size="small"
                            label="RequestType"
                            onChange={(event: SelectChangeEvent<string>) => {
                              const newValue = event.target.value

                              switch (newValue) {
                                case 'AdWord':
                                  setRequestType(RequestType.AdWord)
                                  break
                                case 'FeaturedResult':
                                  setRequestType(RequestType.FeaturedResult)
                                  break
                                case 'SpellingSuggestion':
                                  setRequestType(RequestType.SpellingSuggestion)
                                  break
                              }
                            }}
                          >
                            {[
                              'AdWord',
                              'FeaturedResult',
                              'SpellingSuggestion'
                            ].map((key: string) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  <ListItemText primary={key} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {loading && (
                      <div className={classes.overlaySpinner}>
                        <div className={classes.overlaySpinnerText}>
                          Running
                        </div>
                        <CircularProgress size={80} />
                      </div>
                    )}
                  </>
                )}
                {response && (
                  <div>
                    {response.hasError
                      ? `Error creating ${requestType} for user ${upn}: ${response.errorMessage}`
                      : `Successfully created ${requestType} for user ${upn}`}
                  </div>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!response && (
              <>
                <Button
                  onClick={() => setShowCreateRequestDialog(false)}
                  color="primary"
                  autoFocus
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleCreateItem()}
                  color="primary"
                  disabled={
                    loading ||
                    !upn ||
                    !isUpnValid(upn) ||
                    !displayName ||
                    !requestType
                  }
                >
                  Create
                </Button>
              </>
            )}
            {response && (
              <Button
                onClick={() => setShowCreateRequestDialog(false)}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {newDialogOpen && (
        <>
          <Dialog
            open={newDialogOpen && referenceRowData !== null}
            onClose={(event: object, reason: string) => {
              if (reason !== 'backdropClick') setNewDialogOpen(false)
            }}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="confirmation-modal-title">
              <div className={classesForm.dialogTitleContainer}>
                <div>{getDialogTitle()}</div>
              </div>
            </DialogTitle>
            <DialogContent className={classesForm.dialogContentContainer}>
              {getDialogContent()}
            </DialogContent>
            <DialogActions>
              {referenceRowData.requestType === RequestType.AdWord && (
                <Button
                  color="secondary"
                  onClick={() => {
                    setShowAdsPreview(true)
                  }}
                >
                  Preview
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCreateConfirm(true)
                }}
                className={classesForm.button}
                disabled={!isValid}
              >
                Publish
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCreateConfirm(false)
                }}
                className={classesForm.button}
                disabled={!isValid}
              >
                Create
              </Button>
              <Button color="primary" onClick={() => setNewDialogOpen(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          {referenceRowData.requestType === RequestType.AdWord &&
            showAdsPreview && (
              <Dialog
                open={showAdsPreview}
                onClose={() => setShowAdsPreview(false)}
                aria-labelledby="confirmation-modal"
                aria-describedby="confirmation-modal-description"
              >
                <DialogContent style={{ marginLeft: '-16px' }}>
                  <Ads adItem={getPreviewAdItem()} isRequestPreview={true} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowAdsPreview(false)}
                    color="primary"
                  >
                    <FormattedMessage id="close" defaultMessage="Close" />
                  </Button>
                </DialogActions>
              </Dialog>
            )}
        </>
      )}
      {showConfirmDialog && (
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
        >
          <DialogTitle id="admin_create_confirm_dialog">
            {'Confirm creating request!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <div>
                  {`Are you sure to create the ${requestType?.toString()} for ${upn}`}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowConfirmDialog(false)}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createAdminRequestLocal: (referenceRowData: ISelfServiceRequest) =>
      dispatch(createAdminRequest(referenceRowData))
  }
}

export default connect(null, mapDispatchToProps)(AdminCreateRequest)
