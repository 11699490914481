import { Column } from '@material-table/core'
import AdminTableTooltipClip from 'components/admin/common/AdminTableTooltipClip'
import AdminTableTooltipTitle from 'components/admin/common/AdminTableTooltipTitle'
import StatusClipRequest from 'components/admin/common/StatusClipRequests'
import InlineChangeNotification from 'components/contents/common/InlineChangeNotification'
import { dateFormatOptions } from 'constants/constants'
import createDOMPurify from 'dompurify'
import {
  ISelfServiceRequest,
  RequestStatus,
  RequestType
} from 'models/SelfServiceRequest'
import React from 'react'
import { capitalizeFirstLetter, stripHtml } from 'utils/string'
import { DropDownFilter } from '../../contents/common/DropDownFilter'
import { hasDateExpired } from 'utils/date'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import { AdsResult } from 'models/AdsResult'
import { FeaturedResult } from 'models/FeaturedResult'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { Tooltip } from '@mui/material'
import AccessAlarm from '@mui/icons-material/AccessAlarm'
import dayjs from 'dayjs'

const getStatusColor = (status: RequestStatus): any => {
  switch (status) {
    case RequestStatus.Submitted:
      return 'primary'
    case RequestStatus.Published:
      return 'success'
    case RequestStatus.ToBeDeleted:
    case RequestStatus.Declined:
      return 'error'
    case RequestStatus.Clarification:
      return 'secondary'
    default:
      return 'default'
  }
}

const DOMPurify = createDOMPurify(window)

/**
 * Generate popup columns list
 * @param handleRowRemoveChangeNotification Method to remove the hasChange flag
 * @returns columns list
 */
export const allRequestTableColumns = (
  handleRowRemoveChangeNotification: (rowData: ISelfServiceRequest) => void,
  handleRowRemoveCommentChangeNotification: (
    rowData: ISelfServiceRequest
  ) => void,
  handleRowUpdate: (rowData: ISelfServiceRequest) => void,
  toggleSnackbarVisibility: (visible: boolean) => void,
  setSnackbarMessage: (message: string) => void,
  showDuplicates: (rowData: ISelfServiceRequest) => void
): Column<ISelfServiceRequest>[] => {
  const classes = getStylesAdminSettings()
  const columnDataTranslations: Column<ISelfServiceRequest>[] = [
    {
      title: 'ID',
      field: 'id',
      type: 'string',
      cellStyle: {
        maxWidth: 130
      },
      headerStyle: {
        maxWidth: 130
      },
      filtering: false,
      render: (rowData: ISelfServiceRequest) => (
        <InlineChangeNotification
          rowData={rowData}
          id={rowData.id}
          handleRowRemoveChangeNotification={() =>
            handleRowRemoveChangeNotification(rowData)
          }
          showDuplicates={() => showDuplicates(rowData)}
        />
      ),
      customSort: (a: ISelfServiceRequest, b: ISelfServiceRequest) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: 'Type',
      field: 'requestType',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipClip
          color={'primary'}
          label={rowData.requestType.toString()}
          variant={'outlined'}
          maxWidth={150}
        />
      ),
      width: 150,
      cellStyle: {
        maxWidth: 150
      },
      headerStyle: {
        maxWidth: 150
      },
      filtering: true,
      filterComponent: (props) => (
        <DropDownFilter
          {...props}
          options={[
            RequestType.AdWord.toString(),
            RequestType.FeaturedResult.toString(),
            RequestType.SpellingSuggestion.toString()
          ]}
          multiple={true}
        />
      ),
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        return value.indexOf(rowData.requestType) !== -1
      }
    },
    {
      title: 'Title',
      field: 'title',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipTitle
          className={''}
          basedOn="words"
          unsafeHTML={rowData.title ? DOMPurify.sanitize(rowData.title) : ''}
          maxLine={4}
        />
      ),
      width: 200,
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: false
    },
    {
      title: 'Description',
      field: 'description',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <AdminTableTooltipTitle
          className={''}
          basedOn="words"
          unsafeHTML={
            rowData.description
              ? DOMPurify.sanitize(stripHtml(rowData.description))
              : ''
          }
          maxLine={2}
        />
      ),
      width: 200,
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: false
    },
    {
      title: 'Image',
      field: 'image',
      type: 'string',
      render: (rowData: ISelfServiceRequest) =>
        rowData.imagePreview ? (
          <img
            src={`data:${rowData.imagePreview}`}
            alt="ad url"
            style={{
              width: 80,
              height: 40
            }}
          />
        ) : (
          ''
        ),
      filtering: false,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      sorting: false
    },
    {
      title: 'Link',
      field: 'link',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: ISelfServiceRequest) => {
        const url =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].link
            : (rowData.itemData as FeaturedResult).BestBetUrl

        return (
          <AdminTableTooltipTitle
            basedOn="words"
            text={url}
            maxLine={1}
            className={classes.rowEllipsis}
          />
        )
      },
      filtering: true,
      filterComponent: findDefaultFilterComponent,
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        const url =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].link
            : rowData.requestType === RequestType.FeaturedResult
            ? (rowData.itemData as FeaturedResult).BestBetUrl
            : ''

        if (!url) return false

        return url.toLowerCase().indexOf(value.toLowerCase()) !== -1
      },
      sorting: false
    },
    {
      title: 'Search Terms',
      field: 'search_terms',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => {
        const keywords =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].search_terms
            : (rowData.itemData as FeaturedResult).BestBetKeywords
        return (
          <div>
            {keywords &&
              keywords
                .slice(0, 3)
                .map((searchTerm: string, index: number) => (
                  <AdminTableTooltipClip
                    label={searchTerm}
                    maxWidth={90}
                    variant="outlined"
                    key={index}
                    addMargin={true}
                  />
                ))}
          </div>
        )
      },
      filtering: true,
      filterComponent: findDefaultFilterComponent,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        const keywords =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].search_terms
            : rowData.requestType === RequestType.FeaturedResult
            ? (rowData.itemData as FeaturedResult).BestBetKeywords
            : []

        return keywords.some(
          (key: string) => key.toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
      },
      sorting: false
    },
    {
      title: 'Status',
      field: 'status',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => (
        <StatusClipRequest
          color={getStatusColor(rowData.status)}
          label={rowData.status.toString()}
          variant={'outlined'}
          maxWidth={200}
          rowData={rowData}
          handleRowUpdate={handleRowUpdate}
          isAdminRequest={true}
          toggleSnackbarVisibility={toggleSnackbarVisibility}
          setSnackbarMessage={setSnackbarMessage}
          handleRowRemoveCommentChangeNotification={
            handleRowRemoveCommentChangeNotification
          }
        />
      ),
      width: 200,
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: true,
      filterComponent: (props) => (
        <DropDownFilter
          {...props}
          options={[
            RequestStatus.Submitted.toString(),
            RequestStatus.Published.toString(),
            RequestStatus.Declined.toString(),
            RequestStatus.Clarification.toString(),
            RequestStatus.ToBeDeleted.toString(),
            'Expiring',
            'Duplicate'
          ]}
          multiple={true}
        />
      ),
      customFilterAndSearch: (
        value: any,
        rowData: ISelfServiceRequest
      ): boolean => {
        if (!value || value.length === 0) return true

        let expiration = false
        if (
          value.indexOf('Expiring') !== -1 &&
          rowData.requestType !== RequestType.SpellingSuggestion
        ) {
          const endDate = new Date(rowData.endDate ? rowData.endDate : '')
          const differenceInDays = dayjs().diff(new Date(endDate), 'days', true)
          expiration = differenceInDays > -30
        }

        return (
          (value.indexOf('Expiring') !== -1 ? expiration : false) ||
          (value.indexOf('Duplicate') !== -1 ? !!rowData.duplicates : false) ||
          value.indexOf(rowData.status) !== -1
        )
      }
    },
    {
      title: 'Modified On',
      field: 'modifiedDate',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: ISelfServiceRequest) => {
        if (rowData.modifiedDate)
          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            new Date(rowData.modifiedDate)
          )
        return ''
      },
      filtering: false,
      defaultSort: 'desc'
    },
    {
      title: 'Expiry Date',
      field: 'endDate',
      type: 'string',
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      render: (rowData: ISelfServiceRequest) => {
        let endDate = ''

        if (rowData.endDate) {
          let parsedDate = new Date(rowData.endDate)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          endDate = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        const expired = hasDateExpired(endDate)

        return (
          <div
            style={{
              position: 'relative',
              paddingBottom: rowData.expiresSoon || expired ? '17px' : '6px'
            }}
          >
            <span>
              {endDate}
              <span style={{ verticalAlign: 'super' }}>
                {rowData.expiresSoon && (
                  <Tooltip
                    title={`${
                      rowData.requestType === RequestType.AdWord
                        ? 'Adword'
                        : 'Featured Result'
                    } will expire within the next 30 days.`}
                  >
                    <AccessAlarm
                      style={{ width: '20px', color: 'rgba(234,170,0,1)' }}
                    />
                  </Tooltip>
                )}
                {expired && !rowData.expiresSoon && (
                  <Tooltip
                    title={`${
                      rowData.requestType === RequestType.AdWord
                        ? 'Adword'
                        : 'Featured Result'
                    } has expired.`}
                  >
                    <AccessAlarm style={{ width: '20px', color: '#bc204b' }} />
                  </Tooltip>
                )}
              </span>
            </span>
          </div>
        )
      },
      filtering: false
    },
    {
      title: 'Created By',
      field: 'createdBy',
      type: 'string',
      cellStyle: {
        maxWidth: 200
      },
      headerStyle: {
        maxWidth: 200
      },
      filtering: true,
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Countries',
      field: 'countries',
      type: 'string',
      render: (rowData: ISelfServiceRequest) => {
        const countries =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].countries
            : (rowData.itemData as FeaturedResult).BestBetCountry || []

        const isAllSet = !!countries.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showCountriesList = countries
          .filter((item: string) => item.toLocaleLowerCase() !== 'all')
          .slice(0, isAllSet ? 2 : 3)
        let preFix = ''

        if (isAllSet) {
          showCountriesList = ['All', ...showCountriesList]
          preFix = '!= '
        }

        if (!countries || countries.length === 0) showCountriesList.push('All')

        return (
          <div>
            {countries
              ? showCountriesList.map((country: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      country.toLocaleLowerCase() !== 'all'
                        ? preFix + capitalizeFirstLetter(country)
                        : capitalizeFirstLetter(country)
                    }
                    maxWidth={100}
                    variant="outlined"
                    key={index}
                    color={'primary'}
                    addMargin={true}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 150,
      cellStyle: {
        maxWidth: 150
      },
      headerStyle: {
        maxWidth: 150
      },
      filterComponent: findDefaultFilterComponent,
      customFilterAndSearch: (value: string, rowData: ISelfServiceRequest) => {
        if (!value) return true

        const valueToCheck = value.toLowerCase()

        const countries =
          rowData.requestType === RequestType.AdWord
            ? (rowData.itemData as AdsResult[])[0].countries
            : rowData.requestType === RequestType.FeaturedResult
            ? (rowData.itemData as FeaturedResult).BestBetCountry || []
            : []

        if (!countries) return false

        const isAllSet = !!countries.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        if (!isAllSet) {
          return countries.some((country: string) => {
            return country.toLowerCase().includes(valueToCheck)
          })
        } else {
          return 'all'.includes(valueToCheck)
        }
      }
    },
    {
      title: 'UPN',
      field: 'upn',
      type: 'string',
      hidden: true,
      export: true
    }
  ]

  return columnDataTranslations
}
